var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vx-card',[(_vm.$apollo.loading)?_c('div',[_c('p',[_vm._v("Cargando...")])]):_c('div',[_c('ValidationObserver',{ref:"validateFormFranchiseUpdate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-6"},[_c('ValidationProvider',{attrs:{"rules":"required|max:150","name":"Nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Nombre","placeholder":"Nombre"},model:{value:(_vm.areaTariffInput.name),callback:function ($$v) {_vm.$set(_vm.areaTariffInput, "name", $$v)},expression:"areaTariffInput.name"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col md:w-1/2 w-full mb-6"},[_c('ValidationProvider',{attrs:{"name":"Negociación","vid":"Negociación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full",attrs:{"label":"Negociación","placeholder":"Negociación","autocomplete":""},model:{value:(_vm.areaTariffInput.negotiation),callback:function ($$v) {_vm.$set(_vm.areaTariffInput, "negotiation", $$v)},expression:"areaTariffInput.negotiation"}},_vm._l((_vm.allNegotiations),function(negotiation,index){return _c('div',{key:index,staticClass:"items-select",attrs:{"value":negotiation.id}},[_c('vs-select-item',{attrs:{"value":negotiation.id,"text":("" + (negotiation.name))}})],1)}),0),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-6"},[_c('ValidationProvider',{attrs:{"rules":"required|numeric|min_value:0","name":"Precio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"Precio","placeholder":"0"},model:{value:(_vm.areaTariffInput.price),callback:function ($$v) {_vm.$set(_vm.areaTariffInput, "price", $$v)},expression:"areaTariffInput.price"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"vx-col sm:w-1/2 w-full mb-6"},[_c('ValidationProvider',{attrs:{"rules":"required|numeric|min_value:0|max_value:100","name":"% Ida y Vuelta"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"label":"% Ida y Vuelta","placeholder":"0"},model:{value:(_vm.areaTariffInput.roundTripPercent),callback:function ($$v) {_vm.$set(_vm.areaTariffInput, "roundTripPercent", $$v)},expression:"areaTariffInput.roundTripPercent"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/2 w-full mb-6"},[_c('ValidationProvider',{attrs:{"name":"Tipo de vehículo","vid":"Tipo de vehículo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"w-full",attrs:{"label":"Tipo de vehículo","placeholder":"Tipo de vehículo","autocomplete":""},model:{value:(_vm.areaTariffInput.vehicleType),callback:function ($$v) {_vm.$set(_vm.areaTariffInput, "vehicleType", $$v)},expression:"areaTariffInput.vehicleType"}},_vm._l((_vm.vehicleTypes),function(vehicleType,index){return _c('div',{key:index,staticClass:"items-select",attrs:{"value":vehicleType.id}},[_c('vs-select-item',{attrs:{"value":vehicleType.id,"text":("" + (vehicleType.alias))}})],1)}),0),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('section',[_c('span',{staticClass:"font-semibold"},[_vm._v("Nodos del polígono")]),(_vm.areaTariffInput.polygon.length == 0)?_c('div',{staticClass:"mx-auto"},[_c('p',{staticClass:"py-2 w-full mx-auto text-grey text-center"},[_vm._v(" No hay nodos registrados hasta el momento. ")])]):_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"grid grid-cols-10 gap-x-2 font-semibold mb-3"},[_c('h6',{staticClass:"col-span-3"},[_vm._v("Latitud")]),_c('h6',{staticClass:"col-span-3"},[_vm._v("Longitud")]),_c('h6',{staticClass:"col-span-3"},[_vm._v("Eje Z")]),_c('h6',[_vm._v("Eliminar")])]),_vm._l((_vm.areaTariffInput.polygon),function(node,index){return _c('div',{key:index,staticClass:"grid grid-cols-10 gap-x-2",attrs:{"value":index}},[_c('div',{staticClass:"col-span-3 w-full mb-1"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Latitud"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"0","type":"number"},model:{value:(node.lng),callback:function ($$v) {_vm.$set(node, "lng", $$v)},expression:"node.lng"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-span-3 w-full mb-1"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Longitud"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"0","type":"number"},model:{value:(node.lat),callback:function ($$v) {_vm.$set(node, "lat", $$v)},expression:"node.lat"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-span-3 w-full mb-1"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Eje Z"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"placeholder":"0","type":"number"},model:{value:(node.zAxis),callback:function ($$v) {_vm.$set(node, "zAxis", $$v)},expression:"node.zAxis"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('vs-button',{staticClass:"mb-3",attrs:{"type":"border"},on:{"click":function($event){return _vm.removeNode(index)}}},[_vm._v(" Eliminar ")])],1)])})],2),_c('vs-button',{staticClass:"mr-3 mb-3 mx-auto text-center",on:{"click":_vm.addNode}},[_vm._v(" Agregar Nodo ")])],1),_c('div',{staticClass:"vx-row mt-10"},[_c('div',{staticClass:"vx-col w-full flex justify-end"},[_c('vs-button',{staticClass:"mr-3 mb-3",attrs:{"disabled":invalid},on:{"click":_vm.performMutation}},[_vm._v(" Guardar ")]),_c('vs-button',{staticClass:"mr-3 mb-3",attrs:{"type":"border"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Descartar ")]),(_vm.mode == 'edit')?_c('vs-button',{staticClass:"mr-3 mb-3",attrs:{"type":"border"},on:{"click":_vm.confirmDelete}},[_vm._v(" Eliminar ")]):_vm._e()],1)])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }