<template>
  <div>
    <vx-card>
      <div v-if="$apollo.loading">
        <p>Cargando...</p>
      </div>
      <div v-else>
        <ValidationObserver
          ref="validateFormFranchiseUpdate"
          v-slot="{ invalid }"
        >
          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|max:150"
                name="Nombre"
              >
                <vs-input
                  class="w-full"
                  label="Nombre"
                  placeholder="Nombre"
                  v-model="areaTariffInput.name"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col md:w-1/2 w-full mb-6">
              <ValidationProvider
                name="Negociación"
                vid="Negociación"
                v-slot="{ errors }"
                rules="required"
              >
                <vs-select
                  label="Negociación"
                  v-model="areaTariffInput.negotiation"
                  class="w-full"
                  placeholder="Negociación"
                  autocomplete
                >
                  <div
                    class="items-select"
                    v-for="(negotiation, index) in allNegotiations"
                    :value="negotiation.id"
                    :key="index"
                  >
                    <vs-select-item
                      :value="negotiation.id"
                      :text="`${negotiation.name}`"
                    />
                  </div>
                </vs-select>
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|numeric|min_value:0"
                name="Precio"
              >
                <vs-input
                  class="w-full"
                  label="Precio"
                  placeholder="0"
                  v-model="areaTariffInput.price"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-6">
              <ValidationProvider
                v-slot="{ errors }"
                rules="required|numeric|min_value:0|max_value:100"
                name="% Ida y Vuelta"
              >
                <vs-input
                  class="w-full"
                  label="% Ida y Vuelta"
                  placeholder="0"
                  v-model="areaTariffInput.roundTripPercent"
                />
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mb-6">
              <ValidationProvider
                name="Tipo de vehículo"
                vid="Tipo de vehículo"
                v-slot="{ errors }"
                rules="required"
              >
                <vs-select
                  label="Tipo de vehículo"
                  v-model="areaTariffInput.vehicleType"
                  class="w-full"
                  placeholder="Tipo de vehículo"
                  autocomplete
                >
                  <div
                    class="items-select"
                    v-for="(vehicleType, index) in vehicleTypes"
                    :value="vehicleType.id"
                    :key="index"
                  >
                    <vs-select-item
                      :value="vehicleType.id"
                      :text="`${vehicleType.alias}`"
                    />
                  </div>
                </vs-select>
                <span class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <section>
            <span class="font-semibold">Nodos del polígono</span>
            <div class="mx-auto" v-if="areaTariffInput.polygon.length == 0">
              <p class="py-2 w-full mx-auto text-grey text-center">
                No hay nodos registrados hasta el momento.
              </p>
            </div>
            <div class="mt-2" v-else>
              <div class="grid grid-cols-10 gap-x-2 font-semibold mb-3">
                <h6 class="col-span-3">Latitud</h6>
                <h6 class="col-span-3">Longitud</h6>
                <h6 class="col-span-3">Eje Z</h6>
                <h6>Eliminar</h6>
              </div>
              <div
                class="grid grid-cols-10 gap-x-2"
                v-for="(node, index) in areaTariffInput.polygon"
                :value="index"
                :key="index"
              >
                <div class="col-span-3 w-full mb-1">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                    name="Latitud"
                  >
                    <vs-input
                      class="w-full"
                      placeholder="0"
                      v-model="node.lng"
                      type="number"
                    />
                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-span-3 w-full mb-1">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                    name="Longitud"
                  >
                    <vs-input
                      class="w-full"
                      placeholder="0"
                      v-model="node.lat"
                      type="number"
                    />
                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-span-3 w-full mb-1">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                    name="Eje Z"
                  >
                    <vs-input
                      class="w-full"
                      placeholder="0"
                      v-model="node.zAxis"
                      type="number"
                    />
                    <span class="text-danger text-sm">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div>
                  <vs-button
                    type="border"
                    class="mb-3"
                    @click="removeNode(index)"
                  >
                    Eliminar
                  </vs-button>
                </div>
              </div>
            </div>
            <vs-button class="mr-3 mb-3 mx-auto text-center" @click="addNode">
              Agregar Nodo
            </vs-button>
          </section>

          <div class="vx-row mt-10">
            <div class="vx-col w-full flex justify-end">
              <vs-button
                class="mr-3 mb-3"
                @click="performMutation"
                :disabled="invalid"
              >
                Guardar
              </vs-button>
              <vs-button
                type="border"
                class="mr-3 mb-3"
                @click="$router.go(-1)"
              >
                Descartar
              </vs-button>
              <vs-button
                v-if="mode == 'edit'"
                class="mr-3 mb-3"
                type="border"
                @click="confirmDelete"
              >
                Eliminar
              </vs-button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </vx-card>
  </div>
</template>

<script>
import {
  CREATE_AREA_TARIFF,
  UPDATE_AREA_TARIFF,
  DELETE_AREA_TARIFF,
} from '@/graphql/mutations';
import {
  ALL_NEGOTIATIONS,
  AREA_TARIFF,
  GET_VEHICLE_TYPES,
} from '@/graphql/queries';
export default {
  props: {
    mode: {
      type: String,
      default: 'edit',
    },
  },

  data() {
    return {
      areaTariffInput: {
        id: null,
        name: '',
        negotiation: null,
        vehicleType: null,
        price: null,
        roundTripPercent: null,
        polygon: [],
      },
      allNegotiations: [],
      vehicleTypes: [],
    };
  },

  apollo: {
    areaTariff: {
      query: AREA_TARIFF,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      skip() {
        return this.mode == 'create';
      },
      result({ data }) {
        console.log(data);
        this.areaTariffInput = {
          id: data.areaTariff.id,
          name: data.areaTariff.name,
          negotiation: data.areaTariff.negotiation.id,
          vehicleType: data.areaTariff.vehicleType.id,
          price: data.areaTariff.price,
          roundTripPercent: data.areaTariff.round_trip_percent,
          polygon: data.areaTariff.polygon,
        };
      },
    },
    allNegotiations: {
      query: ALL_NEGOTIATIONS,
      result({ data }) {
        this.allNegotiations = data.allNegotiations;
      },
    },
    vehicleTypes: {
      query: GET_VEHICLE_TYPES,
      result({ data }) {
        this.vehicleTypes = data.vehicleTypes;
      },
    },
  },

  methods: {
    performMutation() {
      if (this.mode == 'edit') {
        this.updateAreaTariff();
      } else {
        this.createAreaTariff();
      }
    },

    updateAreaTariff() {
      this.$apollo
        .mutate({
          mutation: UPDATE_AREA_TARIFF,
          variables: {
            input: {
              id: this.areaTariffInput.id,
              name: this.areaTariffInput.name,
              price: this.areaTariffInput.price,
              roundTripPercent: this.areaTariffInput.roundTripPercent,
              negotiation: this.areaTariffInput.negotiation,
              vehicleType: this.areaTariffInput.vehicleType,
              polygon: this.areaTariffInput.polygon.map((p) => ({
                lng: p?.lng ?? 0,
                lat: p?.lat ?? 0,
                zAxis: p?.zAxis ?? 0,
              })),
            },
          },
        })
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Tarifa por área actualizada!',
            text: 'La tarifa por área fue actualizada correctamente.',
          });
          this.$router.push(`/configuracion/tarifas-por-area`);
        })
        .catch(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Tarifa por área no actualizada',
            text: 'La tarifa por área no fue actualizada.',
          });
        });
    },

    confirmDelete() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirmar eliminación`,
        text: `¿Seguro que quieres eliminar la Tarifa por área?`,
        accept: this.deleteAreaTariff,
        acceptText: 'Eliminar',
        cancelText: 'Cancelar',
      });
    },

    deleteAreaTariff() {
      this.$apollo
        .mutate({
          mutation: DELETE_AREA_TARIFF,
          variables: {
            id: this.areaTariffInput.id,
          },
        })
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Tarifa por área eliminada!',
            text: 'La tarifa por área fue eliminada correctamente.',
          });
          this.$router.push(`/configuracion/tarifas-por-area`);
        })
        .catch(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Tarifa por área no eliminada',
            text: 'La tarifa por área no fue eliminada.',
          });
        });
    },

    createAreaTariff() {
      this.$apollo
        .mutate({
          mutation: CREATE_AREA_TARIFF,
          variables: {
            input: {
              id: this.areaTariffInput.id,
              name: this.areaTariffInput.name,
              price: this.areaTariffInput.price,
              roundTripPercent: this.areaTariffInput.roundTripPercent,
              negotiation: this.areaTariffInput.negotiation,
              vehicleType: this.areaTariffInput.vehicleType,
              polygon: this.areaTariffInput.polygon,
            },
          },
        })
        .then(() => {
          this.$vs.notify({
            color: 'success',
            title: 'Tarifa por área creada!',
            text: 'La tarifa por área fue creada correctamente.',
          });
          this.$router.push(`/configuracion/tarifas-por-area`);
        })
        .catch(() => {
          this.$vs.notify({
            color: 'danger',
            title: 'Tarifa por área no creada',
            text: 'La tarifa por área no fue creada.',
          });
        });
    },

    addNode() {
      this.areaTariffInput.polygon.push({
        lng: null,
        lat: null,
        zAxis: null,
      });
    },

    removeNode(index) {
      this.areaTariffInput.polygon.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/vuexy/apps/simple-calendar.scss';

.simple-calendar {
  &__swatches-custom-trigger {
    height: 41px;
    width: 100%;
    border: 1px solid #d7e2e8;
  }
}
.vue-swatches__trigger__wrapper {
  width: 100%;
}
</style>
